@import 'libs/styles/assets/scss/styles';

html, body {
    width: 100%;
    height: 100%;
    background-color: transparent;
}

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

.clickable {
    cursor: pointer;
}
